import React from 'react';
import MenuItem from './MenuItem';

const MenuSection = ({ title, items = [] }) => {
  return (
    <div name={title} className="my-8 px-4">
      <h2 className="text-3xl font-bold mb-4">{title}</h2>
      <div className='mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 ">
          {items.map((item, index) => (
            <MenuItem key={index} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MenuSection;
